import { Col, Form, InputNumber, Row } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import styles from "../BiformLayout.module.scss";
import FormLabel from "./FormLabel";

const Range = ({
  required,
  name,
  label,
  description = null,
  iconUrl = null,
  maxScore,
  weightage,
  fieldId,
  componentName,
  componentId,
  componentResponseHandler,
  rules,
  count = 5,
  initialValue = null,
  disabled = false
}) => {
  const [value, setValue] = useState();

  const rangeRatingField = {
    id: fieldId,
    title: label,
    description,
    weighage: weightage,
    maxMarks: maxScore,
    type: componentName,
    compRef: componentId
  };

  const rangeRatingAnswer = {
    type: "number",
    number: null,
    field: {
      id: fieldId,
      type: componentName,
      compRef: componentId
    }
  };

  useEffect(() => {
    componentResponseHandler(rangeRatingField, rangeRatingAnswer);
  }, []);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      componentResponseHandler(rangeRatingField, rangeRatingAnswer);
    }
  }, [initialValue]);

  const setComponentResponse = val => {
    rangeRatingAnswer.number = val;
  };

  const handleChange = val => {
    setValue(val);
  };

  return (
    <Row gutter={16}>
      <Col xs={18} sm={18} md={19}>
        <div className="flex">
          <h2 className="text-base text-neutral-12 font-semibold">
            <FormLabel label={label} required={required} iconUrl={iconUrl} />
          </h2>
        </div>
        {description && (
          <ReactMarkdown className={classNames("prose", "pl-6 text-neutral-12")} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
            {description}
          </ReactMarkdown>
        )}
      </Col>
      <Col xs={6} sm={6} md={4}>
        <Form.Item
          initialValue={initialValue}
          name={name}
          rules={[
            { required, message: "Input is required" },
            { type: "number", message: "Input must be a number" }
          ]}
          getValueProps={val => setComponentResponse(val) as any}
          className={styles.range}
        >
          <InputNumber
            disabled={disabled}
            value={value}
            addonAfter="/10"
            controls={false}
            min={1}
            max={10}
            onChange={handleChange}
            onBlur={() => componentResponseHandler(rangeRatingField, rangeRatingAnswer)}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Range;
