import { Form, Input } from "antd";
import React from "react";
import FormLabel from "./FormLabel";

const Email = ({ required, name, label, rules }) => (
  <Form.Item
    name="email"
    label={<FormLabel label={label} required={required} />}
    rules={[
      { type: "email", message: "The input is not valid E-mail!" },
      { required, message: "Please enter your E-mail!" }
    ]}
  >
    <Input />
  </Form.Item>
);

export default Email;
