import Head from "next/head";
import React from "react";

import FormBuilder from "@app/components/BiForm/BiFormLayout";

const BiForm = ({ parameters }) => (
  <>
    <Head>
      <title>Bi Form</title>
      <meta name="description" content="User application page" />
      <link rel="icon" href="/favicon.ico" />
    </Head>

    <FormBuilder parameters={parameters} />
  </>
);

export default BiForm;
