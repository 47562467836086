import React, { FunctionComponent } from "react";
import Image from "next/image";
import styles from "@app/styles/shared/BasicPages.module.scss";

const FormCompleted: FunctionComponent = () => (
  <div className={styles.container}>
    <Image src="icons/formCompletedImage.svg" alt="error" width={1000} height={400} />
    <h1>Submitted Successfully!</h1>
    <p>Thank you! Your response has been recorded.</p>
  </div>
);

export default FormCompleted;
