import { Form, Rate } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../BiformLayout.module.scss";
import FormLabel from "./FormLabel";

// const desc = ["terrible", "bad", "normal", "good", "wonderful"]

const StarRating = ({
  required,
  name,
  label,
  iconUrl = null,
  maxScore,
  weightage,
  fieldId,
  componentName,
  componentId,
  componentResponseHandler,
  rules,
  count = 5,
  initialValue = null,
  disabled = false
}) => {
  const [value, setValue] = useState(0);

  const starRatingField = {
    id: fieldId,
    title: label,
    description: null,
    weighage: weightage,
    maxMarks: maxScore,
    type: componentName,
    compRef: componentId
  };

  const starRatingAnswer = {
    type: "number",
    number: null,
    field: {
      id: fieldId,
      type: componentName,
      compRef: componentId
    }
  };

  useEffect(() => {
    componentResponseHandler(starRatingField, starRatingAnswer);
  }, []);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      componentResponseHandler(starRatingField, starRatingAnswer);
    }
  }, [initialValue]);

  const setComponentResponse = val => {
    starRatingAnswer.number = val;
  };

  return (
    <Form.Item
      initialValue={initialValue}
      name={name}
      label={<FormLabel label={label} required={required} iconUrl={iconUrl} />}
      rules={[{ required, message: "Please select your rating" }]}
      getValueProps={val => setComponentResponse(val) as any}
    >
      <Rate
        onChange={setValue}
        disabled={disabled}
        value={value}
        count={count}
        className={styles.rating}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onBlur={() => componentResponseHandler(starRatingField, starRatingAnswer)}
      />
    </Form.Item>
  );
};

export default StarRating;
