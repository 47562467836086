import React from "react";
import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import styles from "./BiButton.module.scss";

export interface BiButtonProps extends ButtonProps {
  containerClass?: string;
  bicolor?: "primary" | "secondary" | "tertiary";
}

const BiButton: React.FC<BiButtonProps> = ({ children, containerClass, bicolor = "", ...props }) => (
  <div
    className={classNames(
      classNames(containerClass, styles.container, {
        [styles[bicolor]]: bicolor
      })
    )}
  >
    <Button {...props}>{children}</Button>
  </div>
);

export default BiButton;
