import React, { FunctionComponent } from "react";

interface IProps {
  label: string;
  required?: boolean;
  iconUrl?: string;
}

const FormLabel: FunctionComponent<IProps> = ({ label, required = false, iconUrl }) => (
  <span className="text-neutral-12 flex gap-1 md:gap-2">
    {iconUrl && <img src={iconUrl} alt="icon" className="w-6 h-6 object-cover inline-block" />}
    <span>
      {label}
      {required && <span className="text-[#FF3B3B] ml-1 text-xl font-semibold">*</span>}
    </span>
  </span>
);

export default FormLabel;
