import { apiInstance3, apiInstancePython } from "@app/utils/api";

export const getFormRenderData = async (formId: string) => {
  const res = await apiInstancePython.get(`/platform/service/custom_forms/get?Form_Id=${formId}`);
  return res.data;
};

export const sendFormResponse = async formResponse => {
  const res = await apiInstance3.post("/webhook/bi-forms/save-lmsfeedback-response", formResponse);
  return res.data;
};

export const sendDraftResponse = async formResponse => {
  const res = await apiInstance3.post("/webhook/bi-forms/draft-feedback-response", formResponse);
  return res.data;
};

export const getDraftResponse = async (sessionid, userid, studentid, coachid) => {
  const res = coachid
    ? await apiInstance3.get(`/webhook/bi-forms/get-feedback-response?sessionid=${sessionid}&userid=${userid}&coachid=${coachid}`)
    : await apiInstance3.get(`/webhook/bi-forms/get-feedback-response?sessionid=${sessionid}&userid=${userid}&studentid=${studentid}`);
  return res.data;
};
