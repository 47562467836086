import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { captureException } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { nanoid } from "nanoid";
import { get } from "lodash";
import BILoader from "@app/Loaders/BILoader";
import styles from "./BiformLayout.module.scss";
import PhoneNumber from "./components/PhoneNumber";
import Email from "./components/Email";
import LongText from "./components/LongText";
import StarRating from "./components/StarRating";
import RadioButton from "./components/RadioButton";
import Range from "./components/Range";
import { getDraftResponse, getFormRenderData, sendDraftResponse, sendFormResponse } from "./apis";
import FormCompleted from "./components/FormCompleted";
import BiButton from "../BiButton/BiButton";

const getComponent = (field, req, fields, setFields, answers, setAnswers, hideButtons) => {
  const { component, question, formFieldName, maxScore = null, weightage = null, fieldValidation, options, description, icon = null } = field;
  const componentId = component._id;

  // Response Field and Answer builder
  const componentResponseHandler = async (fieldValue, answerValue) => {
    const fieldsArray = fields;
    const answersArray = answers;
    let hasValue = false;
    if (fieldsArray.length) {
      fieldsArray.forEach((option, index) => {
        if (option.id === fieldValue.id) {
          fieldsArray[index] = fieldValue;
          hasValue = true;
        }
      });
      if (hasValue === false) {
        fieldsArray.push(fieldValue);
      }
    } else if (!fieldsArray.length) {
      fieldsArray.push(fieldValue);
    }

    hasValue = false;

    if (answersArray.length) {
      answersArray.forEach((option, index) => {
        if (option.field.id === answerValue.field.id) {
          answersArray[index] = answerValue;
          hasValue = true;
        }
      });
      if (hasValue === false) {
        answersArray.push(answerValue);
      }
    } else if (!answersArray.length) {
      answersArray.push(answerValue);
    }

    setAnswers(answersArray);
    setFields(fieldsArray);
  };

  const details = {
    required: req,
    name: formFieldName,
    label: question,
    description,
    iconUrl: icon,
    maxScore,
    weightage,
    rules: fieldValidation,
    componentId,
    componentName: component.componentName,
    options,
    fieldId: field._id,
    initialValue: field?.answer,
    disabled: hideButtons
  };

  switch (componentId) {
    // phone number
    case "recFHrvfGaHyy3j02":
      return <PhoneNumber {...details} />;

    // email
    case "recvfGaxwGJcBjkPu":
      return <Email {...details} />;

    // long text
    case "rec6m0Nkijgx2AZRh":
      return <LongText {...details} componentResponseHandler={componentResponseHandler} />;

    // star rating
    case "recfc93pcS4WvT2Nf":
      return <StarRating {...details} componentResponseHandler={componentResponseHandler} />;

    // radio button
    case "rec9gqqvylOF0fjaL":
      return <RadioButton {...details} componentResponseHandler={componentResponseHandler} />;

    case "recP95C7jsyEA5NWG":
      return <Range {...details} componentResponseHandler={componentResponseHandler} />;

    default:
      return null;
  }
};

const FormBuilder = ({ parameters }) => {
  const router = useRouter();
  const [formData, setFormData] = useState<any>({});
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formId] = useState(parameters.formid);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const hidden = parameters;
  const landingDate = new Date();
  const landed_at = landingDate.toISOString();

  const [btnLoading, setBtnLoading] = useState({ draftBtn: false, submitBtn: false });
  const [draftData, setDraftData] = useState();
  const formStatus = get(draftData, "form_response.hidden.status", null);

  const formRef = useRef(null);
  const { sessionid, studentid, userid, coachid, draft, hideButtons = false } = parameters;

  useEffect(() => {
    const data = get(draftData, "form_response.answers", null);
    if (!data) return;

    const resultObject = {};
    data?.forEach(item => {
      const key = item.field.id;
      let value;

      switch (item.type) {
        case "number":
          value = item.number;
          break;
        case "text":
          value = item.text;
          break;
        default:
          return null;
      }
      resultObject[key] = value;
    });

    const tempFormData = formData?.fields?.map(item => {
      const value = resultObject[item._id];
      return { ...item, answer: value };
    });

    setFormData({ ...formData, fields: tempFormData });
  }, [draftData]);

  const getFormData = async () => {
    try {
      const response = await getFormRenderData(formId);
      setFormData(response?.data);

      if (draft) {
        const draftResponse = await getDraftResponse(sessionid, userid, studentid, coachid);
        setDraftData(draftResponse?.data);
      }

      setLoading(false);

      if (response.data) return;
      router.push("/404");
    } catch (err) {
      captureException(err);
      router.push("/error");
    }
  };

  useEffect(() => {
    if (!formId) {
      router.push("/404");
    }
    getFormData();

    if (window) {
      window.addEventListener("message", event => {
        // if (event.origin !== "http://localhost:3000") return;
        if (event.data === "triggerButtonClick") {
          handleOutsideButtonClick();
        }
      });
    }
  }, []);

  const getFormResponse = () => {
    const submissionTime = new Date();
    const submitted_at = submissionTime.toISOString();
    hidden.formid = undefined;
    if (hidden?.formtitle) {
      hidden.formtitle = undefined;
    }
    const formResponse = {
      event_id: nanoid(),
      event_type: "form_response",
      form_response: {
        form_id: formId,
        landed_at,
        submitted_at,
        hidden,
        definition: {
          id: formId,
          title: "LMS Feedback Learner to Coach",
          fields
        },
        answers
      }
    };

    return formResponse;
  };

  const handleDraft = async () => {
    const formResponse = getFormResponse();
    setBtnLoading({ ...btnLoading, draftBtn: true });
    try {
      await sendDraftResponse(formResponse);
      window?.parent?.postMessage({ action: "formSavedInDraft" }, "*");
    } catch (err) {
      captureException(err);
      router.push("/error");
    } finally {
      setBtnLoading({ ...btnLoading, draftBtn: false });
    }
  };

  const onFinish = async () => {
    const formResponse = getFormResponse();
    setBtnLoading({ ...btnLoading, submitBtn: true });
    try {
      await sendFormResponse(formResponse);
      setFormSubmitted(true);
      window?.parent?.postMessage({ action: "formSubmitted" }, "*");
    } catch (err) {
      captureException(err);
      window?.parent?.postMessage({ action: "somethingWentWrong" }, "*");
      router.push("/error");
    } finally {
      setBtnLoading({ ...btnLoading, submitBtn: false });
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields.length > 0) {
      window?.parent?.postMessage({ action: "errorFound" }, "*");

      const fieldId = errorFields[0]?.name[0];
      const errorField = document.getElementById(fieldId);
      if (errorField) {
        errorField.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleOutsideButtonClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const renderComponent = () => {
    if (loading) {
      return (
        <div className={styles.loader}>
          <BILoader />
        </div>
      );
    }

    if (formSubmitted) {
      return <FormCompleted />;
    }

    return (
      <div className={styles.biFormContainer}>
        <div className={styles.innerContainer}>
          <Form
            ref={formRef}
            requiredMark={false}
            layout="vertical"
            form={form}
            name="register"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            initialValues={{
              prefix: "+91"
            }}
          >
            <div className={styles.scroll}>
              <h1 className={styles.title}>{parameters?.formtitle || formData?.formName}</h1>
              <h1 className={styles.description}>Please provide the rating on the following parameters</h1>
              {formData?.fields?.map(field => (
                <div className={styles.formComponents} key={nanoid()}>
                  {getComponent(field, formData?.requiredFields?.includes(field._id), fields, setFields, answers, setAnswers, hideButtons)}
                </div>
              ))}
            </div>

            {!hideButtons && (
              <div className="flex gap-4 justify-end mt-4 md:mt-8 ">
                {draft && formStatus !== "SUBMITTED" && (
                  <BiButton
                    loading={btnLoading.draftBtn}
                    bicolor="primary"
                    onClick={handleDraft}
                    className="w-auto !h-10 md:!h-auto px-6 py-3 flex items-center justify-center text-base !text-primary-6 !border-primary-6 !bg-white"
                    containerClass={styles.submitBtn}
                  >
                    Save As Draft
                  </BiButton>
                )}

                <BiButton
                  loading={btnLoading.submitBtn}
                  type="primary"
                  htmlType="submit"
                  bicolor="primary"
                  className="w-auto h-auto px-6 py-3 flex items-center justify-center text-base"
                  containerClass={styles.submitBtn}
                >
                  Submit
                </BiButton>
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  };

  return renderComponent();
};

export default FormBuilder;
