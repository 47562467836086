import axios, { AxiosInstance } from "axios";
import config from "@app/config";

// Instance for node-apis
export const apiInstance2: AxiosInstance = axios.create({
  baseURL: config.api.v2,
  timeout: 30000
});

// V3 routes instance
export const apiInstance3: AxiosInstance = axios.create({
  baseURL: config.api.v3,
  timeout: 30000
});

export const apiInstanceLong: AxiosInstance = axios.create({
  baseURL: config.api.v2,
  timeout: 300000
});

export const apiInstancePython: AxiosInstance = axios.create({
  baseURL: config.pyApi,
  timeout: 300000
});
