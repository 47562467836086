import { Form, Input, Select } from "antd";
import React from "react";
import { nanoid } from "nanoid";
import FormLabel from "./FormLabel";

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 70 }}>
      {Array.from(Array(100), (e, i) => (
        <Select.Option key={nanoid()} value={i}>
          +{i}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const PhoneNumber = ({ required, name, label, rules }) => (
  <Form.Item
    name={name}
    label={<FormLabel label={label} required={required} />}
    rules={[
      { required, message: "Please input your mobile number" },
      { min: 10, message: "Mobile number invalid" },
      { max: 10, message: "Mobile number invalid" }
    ]}
  >
    <Input addonBefore={prefixSelector} />
  </Form.Item>
);

export default PhoneNumber;
