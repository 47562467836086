import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import FormLabel from "./FormLabel";
import styles from "../BiformLayout.module.scss";

const LongText = ({
  required,
  name,
  label,
  iconUrl = null,
  maxScore,
  weightage,
  rules,
  fieldId,
  componentName,
  componentId,
  componentResponseHandler,
  initialValue = null,
  disabled = false
}) => {
  const [value, setValue] = useState("");

  const longTextField = {
    id: fieldId,
    title: label,
    weighage: weightage,
    maxMarks: maxScore,
    type: componentName,
    description: null,
    compRef: componentId
  };

  const longTextAnswer = {
    type: "text",
    text: "",
    field: {
      id: fieldId,
      type: componentName,
      compRef: componentId
    }
  };

  useEffect(() => {
    componentResponseHandler(longTextField, longTextAnswer);
  }, []);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      componentResponseHandler(longTextField, longTextAnswer);
    }
  }, [initialValue]);

  const setComponentResponse = val => {
    longTextAnswer.text = val;
  };

  return (
    <Form.Item
      initialValue={initialValue}
      name={name}
      label={<FormLabel label={label} required={required} iconUrl={iconUrl} />}
      rules={[
        { required, message: "Input is required!" },
        {
          min: rules?.minValue,
          message: `Input must be minimum ${rules?.minValue ?? 5} characters.`
        },
        {
          max: rules?.maxValue ?? 3000,
          message: `Input must be maximum ${rules?.maxValue ?? 300} characters.`
        }
      ]}
      getValueProps={val => setComponentResponse(val) as any}
      className={styles.longText}
    >
      <Input.TextArea
        disabled={disabled}
        onChange={e => setValue(e.target.value)}
        value={value}
        placeholder="Enter your answer"
        autoSize={{ minRows: 3, maxRows: -1 }}
        onBlur={() => componentResponseHandler(longTextField, longTextAnswer)}
      />
    </Form.Item>
  );
};
export default LongText;
