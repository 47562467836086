import BiForm from "@app/containers/BiForm";

const Home = ({ parameters }) => <BiForm parameters={parameters} />;

export default Home;

export async function getServerSideProps(context) {
  const { query } = context;

  return {
    props: {
      parameters: query
    }
  };
}
