import { Form, Radio } from "antd";
import React from "react";
import FormLabel from "./FormLabel";

const RadioButton = ({
  required,
  name,
  label,
  iconUrl = null,
  maxScore,
  weightage,
  options,
  rules,
  n = 10,
  fieldId,
  componentName,
  componentId,
  componentResponseHandler
}) => {
  const radioButtonField = {
    id: fieldId,
    title: label,
    weighage: weightage,
    maxMarks: maxScore,
    type: componentName,
    description: null,
    compRef: componentId
  };

  const radioButtonAnswer = {
    type: "text",
    text: "",
    field: {
      id: fieldId,
      type: componentName,
      compRef: componentId
    }
  };

  const setComponentResponse = value => {
    radioButtonAnswer.text = value;
  };

  return (
    <Form.Item
      name={name}
      label={<FormLabel label={label} required={required} iconUrl={iconUrl} />}
      rules={[{ required, message: "Please select one of the options" }]}
      getValueProps={value => setComponentResponse(value) as any}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onBlur={() => componentResponseHandler(radioButtonField, radioButtonAnswer)}
    >
      <Radio.Group buttonStyle="solid">
        {options.map(e => (
          <Radio.Button key={e._id} value={e.dataValue}>
            {e.dataLabel}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioButton;
